import ProductPrice from '@components/ProductPrice'
import ProductReview from '@components/ProductReview'
import { WebsiteConf } from '@config/website'
import { Button, Carousel, Image as ImageComponent, Link } from '@headless/ui'
import { useProductItem } from '@hooks/ProductItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, memo, useState } from 'react'
import CategoriesAddToCartDialog from './CategoriesAddToCartDialog'
import CustomizeOptions from './CustomizeOptions'
import ProductOptions from './ProductOptions'
import {
  StyledProductAddToCart,
  StyledProductAddToCartMobile,
  StyledProductImg,
  StyledProductItem,
  StyledProductName
} from './styled'

interface ProductItemProps {
  product: any
  showHover?: boolean
  filters?: any
}

const checkExist = (url: string) => {
  return new Promise<boolean>((resolve) => {
    const temp = new Image()
    temp.src = url
    temp.onload = () => {
      if (temp.width > 0 && temp.height > 0) {
        resolve(true)
      } else {
        resolve(false)
      }
    }
  })
}

const ProductItem: FC<ProductItemProps> = ({
  product,
  showHover = true,
  filters
}) => {
  const isMobile: boolean = useMediaQuery('(max-width:767px)')
  const { isCustomized } = WebsiteConf
  const {
    price_range,
    url_key,
    name,
    small_image,
    review_count,
    rating_summary,
    hover_image,
    configurable_options,
    __typename
  } = product

  const options: any[] = product?.configurable_options ?? []
  const isConfigurable: boolean = __typename === 'ConfigurableProduct'
  const variants: any[] = product?.variants ?? []
  const {
    images,
    suffix,
    handleSelect,
    addToCartDialogOpen,
    handleOpenAddToCartDialog,
    handleCloseAddToCartDialog,
    showAddToCartButton,
    setShowAddToCartButton
  } = useProductItem({
    small_image,
    hover_image,
    filters,
    variants,
    configurable_options
  })

  const [hoverImageExists, setSoverImageExists] = useState(false)

  checkExist(images.hover).then((result: boolean) => {
    setSoverImageExists(result)
  })

  const maybeAddToCart = (
    <Button onClick={handleOpenAddToCartDialog}>Add To Cart</Button>
  )

  const maybeCategoriesAddToCartDialog = (
    <CategoriesAddToCartDialog
      product={product}
      handleCloseAddToCartDialog={handleCloseAddToCartDialog}
      addToCartDialogOpen={addToCartDialogOpen}
    />
  )

  const maybeVideoMark = product.video_url ? (
    <div className="videoMark" />
  ) : null

  const isSoldOut: boolean =
    product.stock_status && product.stock_status !== 'IN_STOCK'

  const maybeNewMark =
    product.product_mark &&
    product.product_mark.indexOf('new') > -1 &&
    !isSoldOut ? (
      <div className="newMark" />
    ) : null

  const maybeSale =
    product.product_mark &&
    product.product_mark.indexOf('sale') > -1 &&
    !isSoldOut ? (
      <div className="sale" />
    ) : null

  const maybeBestSellerMark =
    product.product_mark &&
    product.product_mark.indexOf('best seller') > -1 &&
    !isSoldOut ? (
      <div className="bestSellerMark" />
    ) : null

  const maybePreOrer =
    product.presale_product && product.presale_product.status ? (
      <div className="preOrderMark" />
    ) : null

  const maybeSoldOut = isSoldOut ? <div className="soldOutMark" /> : null

  return (
    <StyledProductItem>
      {isMobile ? (
        <Carousel arrows={false}>
          <StyledProductImg>
            <Link href={`/${url_key}${suffix}`} title={name} target="_self">
              <span className="product__image--container">
                <picture
                  className="product__image--wrapper"
                  style={{
                    paddingBottom: `${
                      (isMobile ? 230 / 175 : 360 / 265) * 100
                    }%`
                  }}
                >
                  <ImageComponent
                    className="product__image--photo"
                    src={images.small.url}
                    alt={images.small.label}
                  />
                </picture>
              </span>
            </Link>
          </StyledProductImg>
          <StyledProductImg>
            <Link href={`/${url_key}${suffix}`} title={name} target="_self">
              <span className="product__image--container">
                <picture
                  className="product__image--wrapper"
                  style={{
                    paddingBottom: `${
                      (isMobile ? 230 / 175 : 360 / 265) * 100
                    }%`
                  }}
                >
                  <ImageComponent
                    className="product__image--photo"
                    src={images.hover}
                    alt={images.small.label}
                  />
                </picture>
              </span>
            </Link>
          </StyledProductImg>
        </Carousel>
      ) : (
        <StyledProductImg
          onMouseEnter={() => {
            setShowAddToCartButton(true)
          }}
          onMouseLeave={() => {
            setShowAddToCartButton(false)
          }}
        >
          <Link href={`/${url_key}${suffix}`} title={name} target="_self">
            <span className="product__image--container">
              <picture
                className="product__image--wrapper"
                style={{
                  paddingBottom: `${(isMobile ? 230 / 175 : 360 / 265) * 100}%`
                }}
              >
                <ImageComponent
                  className="product__image--photo"
                  src={images.small.url}
                  alt={images.small.label}
                />
                {hoverImageExists && showHover && (
                  <ImageComponent
                    className="product__image--photo product__image--photo--hover"
                    src={images.hover || images.small.url}
                    alt={images.small.label}
                  />
                )}
              </picture>
            </span>
          </Link>
          {/* {showAddToCartButton && !isMobile ? (
            <StyledProductAddToCart>{maybeAddToCart}</StyledProductAddToCart>
          ) : null} */}
          {/* 暂时隐藏加车的功能，升级nextjs版本后要加回来 */}
          {false && !isMobile ? (
            <StyledProductAddToCart>{maybeAddToCart}</StyledProductAddToCart>
          ) : null}
        </StyledProductImg>
      )}

      {isConfigurable &&
        (isCustomized ? (
          <CustomizeOptions
            options={options}
            variants={variants}
            handleSelect={handleSelect}
          />
        ) : (
          <ProductOptions options={options} />
        ))}
      <StyledProductName className="product__name">
        <Link href={`/${url_key}${suffix}`} title={name} target="_self">
          <span dangerouslySetInnerHTML={{ __html: name }} />
        </Link>
      </StyledProductName>
      <ProductPrice className="product__price" {...price_range} />
      {review_count === 0 ? null : (
        <ProductReview
          count={review_count}
          summary={rating_summary}
          isShowScore={false}
        />
      )}

      {isMobile ? (
        <StyledProductAddToCartMobile onClick={handleOpenAddToCartDialog}>
          <ImageComponent src="/images/miniCart.svg" alt="Add To Cart" />
        </StyledProductAddToCartMobile>
      ) : null}
      {maybeCategoriesAddToCartDialog}
      {maybeVideoMark}
      {maybeNewMark}
      {maybeBestSellerMark}
      {maybeSoldOut}
      {maybeSale}
      {maybePreOrer}
    </StyledProductItem>
  )
}

export default memo(ProductItem)
