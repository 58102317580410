import { DocumentNode, gql } from '@apollo/client'

// export const GET_CATEGORY_PRODUCT_LIST: DocumentNode = gql`
//   query getCategoryProductList(
//     $search: String
//     $filters: ProductAttributeFilterInput
//     $pageSize: Int
//     $currentPage: Int
//     $sort: ProductAttributeSortInput
//   ) {
//     products(
//       search: $search
//       pageSize: $pageSize
//       currentPage: $currentPage
//       filter: $filters
//       sort: $sort
//     ) {
//       items {
//         id
//         name
//         sku
//         uid
//         url_key
//         main_image: image {
//           label
//           url
//         }
//         video_url
//         small_image {
//           label
//           url(width: 384, height: 512)
//         }
//         price_range {
//           ...price_range
//           __typename
//         }
//         hover_image(width: 384, height: 512)
//         show_colors(width: 600, height: 600) {
//           label
//           rgb
//           image
//           hover_image
//           is_selected
//           __typename
//         }
//         stock_status

//         review_count
//         rating_summary

//         ...categoryProduct
//         __typename
//       }
//       total_count
//     }
//   }
//   fragment price_range on PriceRange {
//     minimum_price {
//       discount {
//         amount_off
//       }
//       final_price {
//         currency
//         value
//       }
//       regular_price {
//         value
//         currency
//       }
//     }
//     maximum_price {
//       discount {
//         amount_off
//       }
//       final_price {
//         currency
//         value
//       }
//       regular_price {
//         value
//         currency
//       }
//     }
//   }
//   fragment categoryProduct on ConfigurableProduct {
//     configurable_options {
//       attribute_code
//       id: uid
//       label
//       position
//       values {
//         label
//         swatch_data {
//           value
//         }
//         uid
//       }
//     }
//     variants {
//       ...categoryVariant
//       __typename
//     }
//   }
//   fragment categoryVariant on ConfigurableVariant {
//     attributes {
//       code
//       uid
//       label
//     }
//     product {
//       sku
//       presale_product {
//         status
//         label
//         note
//       }
//       price_range {
//         ...price_range
//         __typename
//       }
//       small_image {
//         label
//         url(width: 384, height: 512)
//       }
//       stock_status
//       media_gallery {
//         disabled
//         label
//         position
//         thumbnail: url(width: 120, height: 160)
//         image: url(width: 1125, height: 1500)
//         ... on ProductVideo {
//           video_content {
//             media_type
//             video_provider
//             video_url
//             video_title
//             video_description
//             video_metadata
//           }
//         }
//         __typename
//       }
//       thumbnail {
//         url(width: 45, height: 60)
//       }
//       hover_image(width: 384, height: 512)
//     }
//   }
// `
export const GET_CATEGORY_PRODUCT_LIST: DocumentNode = gql`
  query getCategoryProductList(
    $search: String
    $filters: ProductAttributeFilterInput
    $pageSize: Int
    $currentPage: Int
    $sort: ProductAttributeSortInput
  ) {
    products(
      search: $search
      pageSize: $pageSize
      currentPage: $currentPage
      filter: $filters
      sort: $sort
    ) {
      items {
        id
        name
        sku
        uid
        url_key
        main_image: image {
          label
          url
        }
        video_url
        small_image {
          label
          url(width: 384, height: 512)
        }
        price_range {
          ...price_range
          __typename
        }
        hover_image(width: 384, height: 512)
        show_colors(width: 600, height: 600) {
          label
          rgb
          image
          hover_image
          is_selected
          __typename
        }
        stock_status

        review_count
        rating_summary

        ...categoryProduct
        __typename
      }
      total_count
    }
  }
  fragment price_range on PriceRange {
    minimum_price {
      discount {
        amount_off
      }
      final_price {
        currency
        value
      }
      regular_price {
        value
        currency
      }
    }
    maximum_price {
      discount {
        amount_off
      }
      final_price {
        currency
        value
      }
      regular_price {
        value
        currency
      }
    }
  }
  fragment categoryProduct on ConfigurableProduct {
    configurable_options {
      attribute_code
      id: uid
      label
      position
      values {
        label
        swatch_data {
          value
        }
        uid
      }
    }
  }
`
