import { WebsiteConf } from '@config/website'
import useMediaQuery from '@mui/material/useMediaQuery'
import { omit } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { GET_CATEGORY_FILTER } from '@graphql/queries/getCategoryFilter'
import { GET_PRODUCT_FILTERS } from '@graphql/queries/getProductFilters'
import { useAwaitQuery, useFilter } from '@headless/hooks'
import { getRouter, getStateFromPath } from '@headless/utils'

const {
  category: { pagination }
} = WebsiteConf

const { PAGE_KEY } = pagination

interface CategoryFilterProps {
  id?: number
  filterInputs: any[]
  q?: string
  filters?: any[]
  aggregations?: any[]
  categories?: any[]
  sortFields?: any[]
}

type NodeType = any & {
  parent_id: number
  children: Array<NodeType>
}

function buildTree(
  categories: Array<{
    parent_id: number
    value: string
    children?: Array<any>
  }>
): Array<NodeType> {
  const roots: Array<NodeType> = []

  categories.forEach((category) => {
    const find = categories.find(
      (temp) => temp.value === category.parent_id?.toString()
    )
    if (!find) {
      roots.push(category)
    } else {
      if (!find.children) {
        find.children = []
      }
      find.children.push(category)
    }
  })

  return roots
}

export const useCategoryFilter = ({
  id,
  filterInputs,
  q,
  filters: propFilters,
  aggregations: propAggregations,
  categories,
  sortFields
}: CategoryFilterProps) => {
  const router = useRouter()
  const categoryQuery: any = useAwaitQuery(GET_CATEGORY_FILTER)
  const filtersQuery: any = useAwaitQuery(GET_PRODUCT_FILTERS)
  const productFiltersQuery: any = useAwaitQuery(GET_PRODUCT_FILTERS)
  const [filterState, filterApi]: any = useFilter()
  const isMobile: boolean = useMediaQuery('(max-width:767px)')

  const [inStockChecked, setInStockChecked] = useState(false)

  const storeConfig = useSelector((state: any) => state.app.storeConfig)
  const [isApplying, setIsApplying] = useState(false)
  const [filters, setFilters] = useState<any>([])
  const [categoryFilter, setCategoryFilter] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [categoryTree, setCategoryTree] = useState<any>([])

  const [categoryOptions, setCategoryOptions] = useState<any>([])

  const [featuredCategories, setFeaturedCategories] = useState<any>([])

  const pagination = useMemo(() => {
    const { grid_page, list_mode, list_page } = storeConfig
    const modeLayout = list_mode.split('-')[0]
    return {
      currentPage: 1,
      pageSize: modeLayout === 'list' ? list_page : grid_page,
      total: 1
    }
  }, [storeConfig])

  const filtersData = useMemo(() => {
    const nextFiltersData = filters.map((filter: any) => {
      filter = omit(filter, ['__typename'])
      filter.options = filter.options.map((option: any) =>
        omit(option, ['__typename'])
      )

      return filter
    })

    return nextFiltersData
  }, [filters])

  const handleClearAll = useCallback(() => {
    setIsApplying(false)

    filterApi.clear()

    setIsApplying(true)
  }, [filterApi])

  useEffect(() => {
    setInStockChecked(filterState.has('quantity_and_stock_status_bucket'))
  }, [filterState, setInStockChecked])

  const handleInStockChagne = useCallback(
    (event: any) => {
      setIsApplying(false)

      const inStockFilter = filtersData.find(
        (temp: any) =>
          temp.attribute_code === 'quantity_and_stock_status_bucket'
      )
      const inStockOption = inStockFilter.options.find(
        (temp: any) => temp.alis_value === 'in_stock'
      )

      if (inStockChecked) {
        filterApi.deleteGroup({ group: inStockFilter.attribute_code })
      } else {
        filterApi.addItem({
          group: inStockFilter.attribute_code,
          item: inStockOption
        })
      }

      setIsApplying(true)
    },
    [filtersData, filterApi, inStockChecked]
  )

  const showClearAll = useMemo(() => !!filterState.size, [filterState])

  useEffect(() => {
    const { pathname } = getRouter(router)

    const nextState = getStateFromPath(
      propAggregations,
      categories,
      sortFields,
      pathname,
      propFilters
    )

    filterApi.setItems(nextState)

    propFilters?.forEach((item) => {
      filterApi.addItem({
        group: item.aggregation.attribute_code,
        item: item.option
      })
    })
  }, [router, filterApi, propAggregations, categories, sortFields, propFilters])

  useEffect(() => {
    const fetchFiltersQuery = async () => {
      try {
        setIsLoading(true)
        const variables: any = q
          ? { search: q }
          : { filters: { category_id: { eq: id } } }

        const { data } = await filtersQuery({
          variables
        })

        const aggregations: any[] = data?.productFilters?.aggregations ?? []
        setFilters([...aggregations])
      } catch (error) {
        return null
      } finally {
        setIsLoading(false)
      }
    }

    fetchFiltersQuery()
  }, [filtersQuery, id, q])

  useEffect(() => {
    const getCategoryTree = async () => {
      let categoryTree: Array<NodeType> = []
      try {
        const { data: productFilterData } = await productFiltersQuery({
          nextFetchPolicy: 'cache-first',
          variables: {
            filters: {
              category_id: {
                eq: id
              }
            }
          }
        })

        const aggregations =
          productFilterData?.productFilters?.aggregations?.concat()

        if (aggregations) {
          const categories = aggregations.find(
            (temp: any) => temp.attribute_code === 'category_id'
          )

          const categoryOptions = categories.options

          setCategoryOptions(categoryOptions)

          const copy: Array<any> = []

          categoryOptions.forEach((temp: any) => {
            copy.push({ ...temp })
          })

          categoryTree = buildTree(copy)
          setCategoryTree(categoryTree)
        }
      } catch (error) {
        console.error(error)
        return null
      }
    }

    if (id) getCategoryTree()
  }, [id, productFiltersQuery])

  useEffect(() => {
    const fetchCategoryFilter = async () => {
      try {
        const { data } = await categoryQuery({
          variables: {
            filters: {
              ids: {
                eq: id
              }
            }
          }
        })

        const result: any = data?.categoryList?.[0] ?? null
        setCategoryFilter({ ...result })
      } catch (error) {
        return null
      }
    }

    if (id) fetchCategoryFilter()
  }, [id, categoryQuery])

  useEffect(() => {
    setFeaturedCategories(
      categories?.filter((item: any) => {
        return (
          item.is_featured &&
          categoryOptions.find(
            (temp: any) => parseInt(temp.value, 10) === item.id
          )
        )
      })
    )
  }, [categories, categoryOptions])

  useEffect(() => {
    if (isApplying) {
      const { search, pathname } = getRouter(router)

      const isSearchPage = pathname.indexOf('/search') === 0

      let subPaths = pathname.split('/')

      subPaths = subPaths.filter(
        (temp: any) =>
          (categories &&
            categories.findIndex((item: any) => item.url_key === temp) > -1) ||
          temp === 'p' ||
          /^\d+$/.test(temp)
      )

      const values = filterState.values()
      let next: any = values.next()
      while (next && next.value) {
        const setValues = next.value.values()
        let temp: any = setValues.next()
        while (temp && temp.value) {
          subPaths.push(temp.value.alis_value)
          temp = setValues.next()
        }
        next = values.next()
      }

      const queryString = search ? `?${search}` : ''
      const pageIndex = subPaths.findIndex((temp: string) => temp === 'p')

      if (isSearchPage) {
        const queryParams: any = new URLSearchParams(search || '')
        queryParams.set(PAGE_KEY, 1)
        router.push(`${subPaths.join('/')}?${queryParams.toString()}`)
      } else {
        // if (pageIndex > -1) {
        //   subPaths[pageIndex + 1] = '1'
        // } else {
        //   subPaths.push(...['p', '1'])
        // }
        router.push(subPaths.join('/') + queryString)
      }

      setIsApplying(false)
    }
  }, [filterState, router, isApplying, categories, pagination])

  return {
    categoryFilter,
    filterApi,
    filtersData,
    filterState,
    isLoading,
    isMobile,
    showClearAll,
    setIsApplying,
    handleClearAll,
    categoryTree,
    featuredCategories,
    handleInStockChagne,
    inStockChecked
  }
}
