import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { ADD_CONFIGURABLE_MUTATION } from '@graphql/mutations/addConfigurableToCart'
import { ADD_PRODUCTS_MUTATION } from '@graphql/mutations/addProductsToCart'
import { CREATE_CART_MUTATION } from '@graphql/mutations/createEmptyCart'
import { GET_CUSTOMER_CART } from '@graphql/queries/getCustomerCart'
import { GET_GUEST_CART } from '@graphql/queries/getGuestCart'
import { useAwaitQuery } from '@headless/hooks'
import { event, findVariant } from '@headless/utils'
import { asyncActions } from '@store/cart'

export const useAddToCart = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const fetchGuestCart = useAwaitQuery(GET_GUEST_CART)
  const fetchCustomerCart = useAwaitQuery(GET_CUSTOMER_CART)
  const [fetchCartId] = useMutation(CREATE_CART_MUTATION)
  const [addToCartMutation] = useMutation(ADD_PRODUCTS_MUTATION)
  const [addConfigurableMutation] = useMutation(ADD_CONFIGURABLE_MUTATION)

  const handleFinalPrice = useCallback((price_range: any) => {
    const { maximum_price, minimum_price } = price_range
    const hasGroup: boolean =
      maximum_price.final_price.value === minimum_price.final_price.value
    const hasSpecial: boolean =
      minimum_price.final_price.value < minimum_price.regular_price.value

    if (hasGroup) {
      if (hasSpecial) {
        return minimum_price.final_price.value
      }

      return minimum_price.regular_price.value
    }

    return minimum_price.final_price.value
  }, [])

  const handleAddToCart = useCallback(
    async ({ values, product, selectedOptions, required }: any) => {
      const { quantity, ...rest } = values
      const { __typename, main_image, name, sku, price_range } = product
      const cartItems: any[] = []
      const result: any = {}

      switch (__typename) {
        case 'ConfigurableProduct':
          const { variants } = product
          const match = findVariant({ variants, selections: rest })

          cartItems.push({
            parent_sku: sku,
            data: {
              parent_sku: sku,
              quantity,
              sku: match.product.sku,
              selected_options: Object.values(rest),
              product_image: match.product.small_image.url
            }
          })

          result.image = match.product.small_image
          result.total = handleFinalPrice(match.product.price_range)
          break
        case 'SimpleProduct':
          cartItems.push({
            quantity,
            sku,
            product_image: main_image.url
          })
          result.image = main_image
          result.total = handleFinalPrice(price_range)
          break
        case 'BundleProduct':
          if (required && selectedOptions.length === 0) {
            // eslint-disable-next-line no-alert
            alert('please select one')
            return
          }

          const uidArray = selectedOptions.map((item: any) => item.uid)

          cartItems.push({
            quantity,
            sku,
            product_image: main_image.url,
            selected_options: uidArray
          })

          break
        default:
          break
      }

      result.name = name
      result.quantity = quantity

      await dispatch(
        asyncActions.addProductsToCart({
          fetchGuestCart,
          fetchCustomerCart,
          fetchCartId,
          addToCartMutation,
          addConfigurableMutation,
          cartItems,
          __typename
        })
      ).then(({ payload }: any) => {
        if (payload) {
          event.emit('receiveCartModal', result)
          enqueueSnackbar(`${name} added to cart success!`, {
            variant: 'success'
          })
        }
      })

      if (product) {
        const getFinalPrice = () => {
          if (__typename === 'ConfigurableProduct') {
            const { variants } = product
            const match = findVariant({ variants, selections: rest })
            return handleFinalPrice(match.product.price_range)
          }
          if (__typename === 'SimpleProduct') {
            return handleFinalPrice(price_range)
          }
          return 0
        }

        const finalPrice = getFinalPrice()

        const currency =
          product?.price_range?.minimum_price?.final_price?.currency ||
          product?.price_range?.maximum_price?.final_price?.currency
        const price = finalPrice

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.dataLayer && window.dataLayer.push({ ecommerce: null })
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.dataLayer &&
          window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              currency,
              value: price * quantity,
              items: [
                {
                  item_name: product.name,
                  item_id: product.uid,
                  price,
                  quantity
                }
              ]
            }
          })

        // Pinterest
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.pintrk &&
          window.pintrk('track', 'addtocart', {
            value: price * quantity,
            order_quantity: quantity,
            currency,
            line_items: [
              {
                product_name: product.name,
                product_id: product.uid,
                product_category: null,
                product_price: price,
                product_brand: 'sweetv'
              }
            ]
          })

        // fbq
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.fbq &&
          window.fbq('track', 'AddToCart', {
            content_ids: [product.sku],
            content_name: product.name,
            content_type: 'product',
            contents: [
              {
                product_name: product.name,
                product_id: product.uid,
                product_category: null,
                product_price: price,
                product_brand: 'ginni'
              }
            ],
            currency,
            value: price * quantity
          })
      }
    },
    [
      addConfigurableMutation,
      addToCartMutation,
      dispatch,
      enqueueSnackbar,
      fetchCartId,
      fetchCustomerCart,
      fetchGuestCart,
      handleFinalPrice
    ]
  )

  return {
    handleAddToCart
  }
}
